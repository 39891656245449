<template>
  <div id="limit-numbers" class="container">
    <div class="page-header mb-1">
      <strong>เลขอั้น</strong>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">

        <h5 class="mb-1"><i class="fas fa-trophy"></i> อัตราจ่าย</h5>
        <table class="table table-bordered table-warning table-hover mb-0">
          <thead>
            <tr>
              <th class="text-center">อัตราจ่าย </th>
              <th width="13%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rate in rates" :key="rate.rateId">
              <td class="text-center">
                <h6 class="mb-0">{{rate.rateTitle}}</h6>
              </td>
              <td v-for="bet in marketOpenBets" :key="bet.code" class="text-right" :class="[{'bg-secondary':!rate.rates[bet.code].isAvailable}]">
                <span v-if="rate.rates[bet.code].isAvailable">{{ rate.rates[bet.code].pay | amountFormat }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-4">
          <h5>
            <span class="mr-2">เลขอั้น</span>
            <span class="text-primary mr-2">{{ roundName }}</span>
            <span class="text-secondary mr-2" style="text-decoration: underline;">(เฉพาะงวด)</span>
            <span>วันที่ <span class="text-primary">{{ roundDate }}</span></span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
          </h5>

          <div v-if="limitNumbers.length" class="row">
            <LimitNumbers v-for="bet in marketOpenBets" :key="bet.code" class="col-md-4" :bet="bet" :data="data" />
          </div>
          <div v-else class="alert alert-warning mb-0">ไม่มีเลขอั้น</div>
        </div>

      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import { marketOpenBets } from "@/configs/market.config"
import LimitNumbers from './components/LimitNumbers'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundLimitNumbers',
  components: {
    LimitNumbers
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    marketOpenBets() {
      return marketOpenBets
    },
    rates() {
      if(!this.data)
        return []

      return this.data.payouts
    },
    limitNumbers() {
      if(!this.data)
        return []

      const closeNumbers = [].concat(this.data.round.closeNumbers.threeNumberTop, this.data.round.closeNumbers.threeNumberTode, this.data.round.closeNumbers.twoNumberTop, this.data.round.closeNumbers.twoNumberBottom, this.data.round.closeNumbers.runTop, this.data.round.closeNumbers.runBottom)
      const payHalfNumbers = [].concat(this.data.round.payHalfNumbers.threeNumberTop, this.data.round.payHalfNumbers.threeNumberTode, this.data.round.payHalfNumbers.twoNumberTop, this.data.round.payHalfNumbers.twoNumberBottom, this.data.round.payHalfNumbers.runTop, this.data.round.payHalfNumbers.runBottom)

      return closeNumbers.concat(payHalfNumbers)
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundLimitNumbers(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.loadRoundData()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
